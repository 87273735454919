/******************************************************
 * 
 * This page is designed to improve the % of people that signup primarily,
 * It's also supposed to increase the % that create a company.
 * 
 * We need to do everything that we normally do to create a user and then also create a company
 * 
 * Need to update html
 * Need to update verification
 * 
 * ****************************************************/

/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React, { useState,useRef,useEffect } from 'react';
//import { useAppContext } from "../libs/contextLib";
import withWrapper from '../components/wrapper.js';
import { navigate, Link } from "gatsby";
import checkmark from '../images/checkmark.svg';
import Amplify, { Auth } from "aws-amplify";
import awsconfig from '../aws-exports';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import {SectorNames,SectorNums,IndustryNames,IndustryNums} from '../components/industryInfo.js';
import SelectSearch from 'react-select-search';


import './signup.css';
const passwordValidator = require('password-validator');
Amplify.configure(awsconfig);

// create a password schema
const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();


function _inner (props) {
  const companyNameRef = useRef(null);
  const endDateRef = useRef(null);
  const sectorsRef = useRef(null);
  const industryRef = useRef(null);
  const reasonsRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const password2Ref = useRef(null);
  const passwordHelpRef = useRef(null);
  const checkRef = useRef(null);
  const ulRef = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [checked, setChecked] = useState(false);
  let infoRef = React.createRef();
  const [companyName, setCompanyName] = useState("");
  const [endDate, setEndDate] = useState("12");
  const [sectorSel, setSectorSel] = useState("");
  const [industrySel, setIndustrySel] = useState("");
  const [Industries, setIndustries] = useState([]);
  const [industryPlaceholder, setIndustryPlaceholder] = useState("Choose Sector First");
  const [reasonsSel, setReasonsSel] = useState("");
  const [reasonsPlaceholder] = useState("Reason for company valuation");

  function validateEmail(elementValue){      
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(elementValue); 
  }
  function passwordValidateError(errors) {
    let words=["min","digits","symbols","uppercase","lowercase"];
    words.forEach((w,i) => {
      setSt(w,i);
    });
    function setSt(word,node) {
      if(errors.includes(word)) {
        ulRef.current.children[node].style.fontWeight="500";
        ulRef.current.children[node].style.color="red";
      } else {
        ulRef.current.children[node].style={};
      }
    }
  }
  let months=[];
  let monthNames=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  for(let a=0;a<12;a++) {
    months.push({name:monthNames[a], value: (a+1).toString()});
  }
  let Sectors=[];
  SectorNames.forEach((v,i) => {
    Sectors.push({name:v, value: SectorNums[i].toString()});
  });
  let reasons=[];
  let reasonNames=["Sale/Merger","Equity/Debt Financing","Employee Stock Ownership","Decision Making","Other"];
  reasonNames.forEach((v,i) => {
    reasons.push({name:v, value: v});
  });
  
  function setIndustry(val) {
    let Ind=[];
    IndustryNames.forEach((v,i) => {
      if(Math.floor(IndustryNums[i]/1000)==val) {
        Ind.push({name:v, value: IndustryNums[i].toString()});
      }
    });
    setIndustries(Ind);
    setIndustryPlaceholder("Industry");
  }
  

  function validateForm() {
    if(passwordRef.current) {
      const passErrors=schema.validate(password,{ list: true });
      passwordValidateError(passErrors);
      if(sectorSel>0)
        sectorsRef.current.style.visibility='visible';
      else
        sectorsRef.current.style.visibility='hidden';
      if(industrySel>0)
        industryRef.current.style.visibility='visible';
      else
        industryRef.current.style.visibility='hidden';
      if(companyName!="")
        companyNameRef.current.style.visibility='visible';
      else
        companyNameRef.current.style.visibility='hidden';
      if(endDate!=0)
        endDateRef.current.style.visibility='visible';
      else
        endDateRef.current.style.visibility='hidden';
      if(passErrors.length===0)
        passwordRef.current.style.visibility='visible';
      else
        passwordRef.current.style.visibility='hidden';
      if(passErrors.length===0 && password2===password)
        password2Ref.current.style.visibility='visible';
      else
        password2Ref.current.style.visibility='hidden';
      if(validateEmail(email))
        emailRef.current.style.visibility='visible';
      else
        emailRef.current.style.visibility='hidden';
      if(checked==true)
        checkRef.current.style.visibility='visible';
      else
        checkRef.current.style.visibility='hidden';
      if(reasonsSel!="")
        reasonsRef.current.style.visibility='visible';
      else
        reasonsRef.current.style.visibility='hidden';
    }
        
    let val=reasonsSel!="" && endDate!=0 && sectorSel>0 && industrySel>0 && companyName!="" && validateEmail(email) && password.length > 7 && password===password2 && checked==true;
    return val;
  }
  function showHelp() {
    passwordHelpRef.current.style.display='block';
  }
  function hideHelp() {
    passwordHelpRef.current.style.display='none';
  }
  function setIsLoading() {
      
  }

  async function handleSubmit(event) {
    //console.log("companyName");
    //console.log(companyName);
    event.preventDefault();
    setIsLoading(true);
    Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            'custom:companyName': companyName,
            'custom:endMonth': endDate,
            'custom:industry': industrySel,
            'custom:sector': sectorSel,
            'custom:reason': reasonsSel
          }
        }).then(() => {
          console.log("success");
          if(window.location.hostname=="www.valtrace.com") {
            ReactGA.event({
              category: 'Signup',
              action: 'SignupDuel'
            });
          }
          props.setText("Check email for confirmation link.");
          setTimeout(() => {
            navigate('/confirmation?email='+email,{
              state: { password: password },
            });
          }, 500);
        }).catch(err => {
          if (err.code === 'UsernameExistsException') {
            Auth.resendSignUp(email).then(() => {
            setTimeout(() => {
              navigate('/confirmation?email='+email,{
                state: { password: password },
              });
            }, 500);
            props.setText("Check email for new confirmation link.");
            }).catch(err => {
              props.setText("Failure: "+err.message);
              
            });
          } else {
            props.setText("Failure: "+err.message);
            console.log(err);
          }
        });
  }
  let topstyle={position: 'relative',
    width: '100%',
  };
  
  let innerStyle={
    
  };
  
  return (
    <div className="LoginForm" style={topstyle} >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create Company</title>
        <link rel="canonical" href="https://www.valtrace.com/createCompany/" />
        <html lang="en" />
      </Helmet>
      <div style={innerStyle} >
        <style>{`
          .info_table td {
            padding:10px;
            padding-left:0px;
            border-bottom:1px solid hsla(0,0%,0%,0.06);
          }
          .companyInfo {
            text-align: center;
            /* May want to do this if there is risk the container may be narrower than the element inside */
            white-space: nowrap;
          }
           
          /* The ghost, nudged to maintain perfect centering */
          .companyInfo:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -0.25em; /* Adjusts for spacing */
          }
          
          /* The element to be centered, can also be of any width and height */ 
          .bl_centered {
            display: inline-block;
            vertical-align: middle;
            width: 300px;
          }  
            
          li {
            margin-bottom: 1px;
          }
          .arrow-left {
            width: 0; 
            height: 0; 
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent; 
            border-right:10px solid #aaa;
          }
          .LoginForm input {
            border-radius: 2px;
            border: 1px solid #aaa;
            padding: 2px;
          }
          
          .linet {
            overflow: hidden;
            text-align: center;
          }
          
          .linet:before,
          .linet:after {
            background-color: hsla(0,0%,0%,0.24);
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 50%;
          }
          
          .linet:before {
            right: 0.5em;
            margin-left: -50%;
          }
          
          .linet:after {
            left: 0.5em;
            margin-right: -50%;
          }
        `}
        </style>
        <div style={{padding:'20px',paddingTop:'0px',textAlign:'center',fontSize:'18px'}}>
          <div>Value a private company</div>
          <div>with our free AI powered tool.</div>
        </div>
        <div className='companyInfo' style={{}} ref={infoRef}>
          <div className='bl_centered' style={{minWidth:'380px' }}>
            <form onSubmit={handleSubmit} style={{marginBottom:'0'}}>
              <div className="linet">Company</div>
              <table className="info_table" style={{marginBottom:'0',fontSize:'15px'}}>
                <tbody>
                  <tr>
                    <td style={{textAlign:'right'}}><span style={{display: 'inline-block', width: '112px'}}>Company Name</span></td>
                    <td>
                      <input style={{width:'250px'}} value={companyName} onChange={e => setCompanyName(e.target.value)} />
                      <img ref={companyNameRef} src={checkmark} style={{position:'relative',right:'13px', visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
                    </td>
                  </tr><tr>
                    <td style={{textAlign:'right'}}><span>Fiscal Year End</span></td>
                    <td style={{position:'relative'}}>
                      <SelectSearch
                        options={months}
                        name="Month" 
                        placeholder="Month"
                        value={endDate}
                        onChange={setEndDate}
                        search
                      />
                      <img ref={endDateRef} src={checkmark} style={{position:'absolute',right:'9px', top:'17px',visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
                    </td>
                  </tr>
                  <tr>
                    <td style={{textAlign:'right'}}><span>Sector </span></td>
                    <td style={{position:'relative'}}>
                      <SelectSearch
                          options={Sectors}
                          name="Sector" 
                          placeholder="Sector"
                          value={sectorSel}
                          onChange={(v) => {setSectorSel(v); setIndustry(v);}}
                          search
                        />
                      <img ref={sectorsRef} src={checkmark} style={{position:'absolute',right:'9px', top:'17px',visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
                    </td>
                  </tr>
                  <tr>
                    <td style={{border: 'none',textAlign:'right'}}><span>Industry</span></td>
                    <td style={{border: 'none',position:'relative'}}>
                      <SelectSearch
                        options={Industries}
                        name="Industry" 
                        placeholder={industryPlaceholder}
                        value={industrySel}
                        onChange={setIndustrySel}
                        search
                      />
                      <img ref={industryRef} src={checkmark} style={{position:'absolute',right:'9px', top:'17px',visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
                    </td>
                  </tr>
                  <tr>
                    <td style={{border: 'none',textAlign:'right'}}><span>Valuation Reason</span></td>
                    <td style={{border: 'none',position:'relative'}}>
                      <SelectSearch
                        options={reasons}
                        name="reasons" 
                        placeholder={reasonsPlaceholder}
                        value={reasonsSel}
                        onChange={setReasonsSel}
                      />
                      <img ref={reasonsRef} src={checkmark} style={{position:'absolute',right:'9px', top:'17px',visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="linet">User</div>
              <table className="info_table" style={{marginBottom:'0',fontSize:'15px'}}>
                <tbody>
                  <tr>
                    <td style={{textAlign:'right'}}><span>Email</span></td>
                    <td>
                      <input placeholder="Email" autoFocus style={{width:'250px'}} type="email" className="form-control Hip" value={email} onChange={e => setEmail(e.target.value)} />
                      <img ref={emailRef} src={checkmark} style={{position:'relative',right:'13px',visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
                    </td>
                  </tr>
                  <tr>
                    <td style={{textAlign:'right'}}><span>Password</span></td>
                    <td>
                      <div style={{display:'inline-block',position:'relative'}}>
                        <input onFocus={showHelp} onBlur={hideHelp} type="password" placeholder="Password" style={{width:'250px'}} className="form-control Hip" value={password} onChange={e => setPassword(e.target.value)} />
                        <div ref={passwordHelpRef} style={{
                          display:'none',
                          position:'absolute',
                          right:'calc(-100% + 5px)',
                          width:'18em',
                          border:'1px solid #aaa',
                          borderRadius: '2px',
                          dropShaddow:'2px 1px 1px .2',
                          backgroundColor:'#f3f3f3',
                          textAlign:'left',
                          fontSize:'13px',
                          padding:'10px',
                          transform:'translateY(calc(-50%))',
                          top:'50%'
                        }}>
                          <div style={{position:'absolute',left:'-10px',top: 'calc(50% - 10px)'}} className='arrow-left' />
                          <div>Password should contain:</div>
                          <ul ref={ulRef} style={{marginLeft:'20px',marginBottom:'0'}}>
                            <li>Minimum length 8 characters</li>
                            <li>Numerical character (0-9)</li>
                            <li>Special characters</li>
                            <li>Uppercase letter</li>
                            <li>Lowercase letter</li>
                          </ul>
                        </div>
                      </div>
                      <img ref={passwordRef}  src={checkmark} style={{position:'relative', right:'13px', visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
                    </td>
                  </tr>
                  <tr>
                    <td style={{border: 'none',textAlign:'right'}}><span style={{display: 'inline-block', width: '112px'}}>Repeat Password</span></td>
                    <td style={{border: 'none'}}>
                      <input type="password" placeholder="Repeat Password" style={{width:'250px'}} className="form-control Hip" value={password2} onChange={e => setPassword2(e.target.value)} />
                      <img ref={password2Ref} src={checkmark} style={{position:'relative',right:'13px', visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{padding:'5px 0px', textAlign:'center',fontSize: '14px'}} className="form-group">
                <label class="container">
                  I agree to the <Link to='/terms/' className='topMenuHover'><span>Site Terms</span></Link>
                  <input type="checkbox" value={checked} onChange={e => setChecked(!checked)} />
                  <span class="checkmark"></span>
                </label>
                <img ref={checkRef} src={checkmark} style={{position:'relative',right:'-4px',bottom:'4px', visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
              </div>
              <div style={{clear:'both',padding:'5px 0px',width:'100%', textAlign:'center'}}>
                <button type="submit" className='btn' disabled={!validateForm()} >Sign up</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withWrapper(_inner);